import 'waypoints/lib/jquery.waypoints.min.js';

// loading  
let preloaded = 0
let totalImages = Array.from(document.querySelectorAll('img'))
let render1 = null
let render2 = null
let render3 = null
let render4 = null
let render5 = null
const mobileW = 992;
var menuPos = [];

$(window).on("resize", function(){
    if($(window).width() > 1200){
        location.reload();
    }
})

const delayDOMLoaded = function () {
    //reload時, 清除捲軸紀錄, 並回到頂層
    history.scrollRestoration = "manual";
    // $(window).on('beforeunload', function(){
    //     window.scrollTo(0,0);
    // });

    totalImages = Array.from(document.querySelectorAll('img'))

    for (let i = 0, l = totalImages.length; i < l; i++) {
        var tempImage = new Image()

        tempImage.addEventListener(
            'load',
            function () {
                preloaded++

                var loadPercent = document.querySelector(".load-percent");
                var loadBar = document.querySelector(".progress-value");

                loadPercent.innerHTML = parseInt(preloaded / totalImages.length * 100);
                loadBar.style.width =  parseInt(preloaded / totalImages.length * 100) + '%'

                if (preloaded === l) {
                    // console.log('All images loaded, run animate progress.')
                    $('body').removeClass('hidebar')
                    $('.loading-wrap').addClass('fadeout')
                    $('.section1-cloud .cloud1').addClass('fadein')
                    $('.section1-cloud .cloud2').addClass('fadein2')
                    $(".svg-img").remove()
                    $('.svg').addClass('svgFadeIn')
                    $('.svg').html('<object class="animated-svg" type="image/svg+xml" data="/images/pen-animate.svg"></object>')
                    
                    $(".side-nav a").each(function(){
                        let section_target = $(this).data("target");

                        if($(section_target).length > 0){
                            if(section_target == "#section1"){
                                menuPos[section_target] = 0;
                            }else if(section_target == "#refactoryFooter"){
                                menuPos[section_target] = $(section_target).offset().top - $(window).height();
                            }else{
                                menuPos[section_target] = $(section_target).offset().top;
                            }
                        }
                    })

                    if(window.location.hash){
                        // $(".slideTo[href='')")
                        window.scrollTo(0, menuPos[window.location.hash]);
                    }

                    setTimeout(function(){
                        $('.loading-wrap').remove()
                    },2000)

                    
                    $(".section1-content").find(".title").addClass('fadeinUp')
                    $(".section1-content").find(".middle-content").addClass('fadeinUp')

                    runAnimateProgress()   
                }
            },
            true
        )

        tempImage.src = totalImages[i].src
    }
}

window.addEventListener(
    'DOMContentLoaded',
    function () {
        var element =  document.getElementById('loading');
        if (typeof(element) != 'undefined' && element != null){
            let totalImages = Array.from(document.querySelectorAll('img'))

            if (totalImages.length === 0) {
                setTimeout(function () {
                    delayDOMLoaded()
                }, 1000)
            } else {
                delayDOMLoaded()
            }
        } // if exist
    },
    true
)

document.addEventListener('mousewheel', function(e) {
    // e.preventDefault();
}, { passive: false });

// run animate
function runAnimateProgress() {
    mapMarker();
    render1 = animateSection1();
    render2 = animateSection2();
    render3 = animateSection3();
    render4 = animateSection4();

}// runAnimateProgress

function schoolHide(){
    $(".part.sport").css({
        "opacity": 0,
        "transform": 'translateX(-10vw) translateZ(0)'
    })

    $(".part.classroom").css({
        "opacity": 0,
        "transform": 'translateX(10vw) translateZ(0)'
    })

    $(".part.classroom-building").css({
        "opacity": 0,
        "transform": 'translateX(0) translateZ(0)'
    })

    $(".part.classroom-building").css({
        "opacity": 0,
        "transform": 'translateX(0) translateZ(0)'
    })

    $(".part.desk-and-playground .desk").css({
        "opacity": 0,
        "transform": 'translateY(-5vh) translateZ(0)'
    })

    $(".part.desk-and-playground .playground").css({
        "opacity": 0,
        "transform": 'scale(1.05) translateZ(0)'
    })
}

function mapMarker(){
    const markers = Array.from(
        document.querySelectorAll('.taiwan-map-makers .location')
    )
    const cards = Array.from(
        document.querySelectorAll('.maker-information .location')
    )

    const closeButton = Array.from(
        document.querySelectorAll('.maker-information .mobile-close-button')
    )
    closeButton.forEach(function (item) {
        item.addEventListener('click', function () {
            item.parentElement.parentElement.classList.remove('animate-end')
            item.parentElement.parentElement.classList.remove('animate')
        })
    })

    markers.forEach(function (item, index) {
        item.addEventListener(
            'mouseenter',
            function () {
                item.querySelector('path').style.transform = 'matrix(1, 0, 0, 1, -2, -6)'
                item.querySelector('ellipse').style.opacity = 0.5
                item.querySelector('circle').style.opacity = 1
            },
            false
        )

        item.addEventListener(
            'mouseleave',
            function () {
                if (!item.classList.contains('animate-end')) {
                    item.querySelector('path').style.transform = 'matrix(1, 0, 0, 1, -2, -2)'
                    item.querySelector('ellipse').style.opacity = 0
                    item.querySelector('circle').style.opacity = 0
                }
            },
            false
        )

        item.addEventListener(
            'click',
            function () {
                let card = document.querySelector('#' + item.id.replace('maker-', ''))

                markers.forEach(function (m) {
                    m.classList.remove('animate-end')
                    m.querySelector('path').removeAttribute('style')
                    m.querySelector('ellipse').removeAttribute('style')
                    m.querySelector('circle').removeAttribute('style')
                })

                item.classList.add('animate-end')
                item.querySelector('path').style.transform = 'matrix(1, 0, 0, 1, -2, -6)'
                item.querySelector('ellipse').style.opacity = 0.5
                item.querySelector('circle').style.opacity = 1

                if (card) {
                    cards.forEach(function (card) {
                        card.classList.remove('animate-end')
                        card.classList.remove('animate')
                    })

                    card.classList.add('animate')

                    setTimeout(function () {
                        card.classList.add('animate-end')
                    }, 100)
                }
            },
            false
        )

        if (index === 0 && window.innerWidth >= mobileW) {
            let card = document.querySelector('#' + item.id.replace('maker-', ''))

            markers.forEach(function (m) {
                m.classList.remove('animate-end')
                m.querySelector('path').removeAttribute('style')
                m.querySelector('ellipse').removeAttribute('style')
                m.querySelector('circle').removeAttribute('style')
            })

            item.classList.add('animate-end')
            item.querySelector('path').style.transform = 'matrix(1, 0, 0, 1, -2, -6)'
            item.querySelector('ellipse').style.opacity = 0.5
            item.querySelector('circle').style.opacity = 1

            if (card) {
                cards.forEach(function (card) {
                    card.classList.remove('animate-end')
                    card.classList.remove('animate')
                })

                card.classList.add('animate-end')
            }
        }
    })
} // mapMarker

const animateSection1 = function () {
    $('.cards-wrapper').waypoint(function(direction) {
        if(direction === "down"){
            $('.svg').removeClass("svgFadeIn").html("")
            $(".section1-content").find(".title").removeClass('fadeinUp')
            $(".section1-content").find(".middle-content").removeClass('fadeinUp')

            //學校出現
            $('.cloud-image').css({"opacity":0, "transform": "scale(6)"})
            $(".section1-content .content").css("opacity",0)
            $(".school").addClass("slideUp")

        }else if(direction === "up"){
            $('.svg').addClass("svgFadeIn")
            $('.svg').html('<object class="animated-svg" type="image/svg+xml" data="/images/pen-animate.svg"></object>')
            $(".section1-content .content").css("opacity",1)
            $(".section1-content .title").addClass('fadeinUp')
            $(".section1-content .middle-content").addClass('fadeinUp')

            $('.cloud-image').css({"opacity":1, "transform": "scale(1)"})
            $(".school").removeClass("slideUp")

        }
    }, {
        offset: '100%'
    });

    $('.cards-wrapper .card-show').each(function(index, value){
        $(this).waypoint(function(direction) {
            var cardItem = $(this.element).data("target")
            if(direction === "down"){
                $(this.element).addClass("show");
                $(this.element).next(".card").addClass("show");

                if(cardItem == 1){
                    //收納空間不足
                    $(".school").css("transform",'scale(2.4) translate(18vw, 3vh) translateZ(0)')
                    $(".part.classroom-building .hall").css("opacity",0.5)
                    $(".part.classroom-building .classroom-inside").css("opacity",1)
                    $(".part.desk-and-playground .playground").css("transform", 'translateY(-4vh) translateZ(0)')
                }else if(cardItem == 2){
                    // 機能受限
                    $(".school").css("transform",'scale(2.4) translate(-2vh, 4vh) translateZ(0)')
                    $(".part.classroom-building .hall").css("opacity",1)
                    $(".part.classroom-building .classroom-inside").css("opacity",0.5)
                    $(".part.desk-and-playground .playground").css("transform", 'translateY(5vh)')
                }else if(cardItem == 3){
                    // 學習場域擴充
                    $(".school").css("transform",'scale(1.5) translate(-25vw, 6vh) translateZ(0)')
                    $(".part.classroom-building .hall").css("opacity",1)
                    $(".part.classroom-building .classroom-inside").css("opacity",1)
                    $(".part.desk-and-playground .playground").css("transform", 'translateY(-4vh) translateZ(0)')
                }
            }else if(direction === "up"){
                $(this.element).removeClass("show");
                $(this.element).next(".card").removeClass("show");

                if(cardItem == 1){
                    $(".school").css("transform",'scale(1) translate(0, 0) translateZ(0)')
                    $(".part.classroom-building .hall").css("opacity",1)
                    $(".part.classroom-building .classroom-inside").css("opacity",1)
                    $(".part.desk-and-playground .playground").css("transform", 'translateY(0vh)')
                }
            } // end direction
        }, {
            offset: '70%'
        });

        $(this).waypoint(function(direction) {
            var cardItem = $(this.element).data("target")
            if(direction === "up"){
                if(cardItem == 1){
                    //收納空間不足
                    $(".school").css("transform",'scale(2.4) translate(18vw, 3vh) translateZ(0)')
                    $(".part.classroom-building .hall").css("opacity",0.5)
                    $(".part.classroom-building .classroom-inside").css("opacity",1)
                }else if(cardItem == 2){
                    // 機能受限
                    $(".school").css("transform",'scale(2.4) translate(-2vh, 4vh) translateZ(0)')
                    $(".part.classroom-building .hall").css("opacity",1)
                    $(".part.classroom-building .classroom-inside").css("opacity",0.5)
                    $(".part.desk-and-playground .playground").css("transform", 'translateY(5vh)')
                }else if(cardItem == 3){
                    // 學習場域擴充
                    $(".school").css("transform",'scale(1.5) translate(-25vw, 6vh) translateZ(0)')
                    $(".part.classroom-building .hall").css("opacity",1)
                    $(".part.classroom-building .classroom-inside").css("opacity",1)
                }
            } // end direction
        }, {
            offset: '10%'
        });
    });

    // 學校回復
    $(".section3").waypoint(function(direction) {
        $(".school").css("transform",'scale(1) translate(0, 0) translateZ(0)')
        if(direction === "down"){
            $("body").addClass("bg-gray")
            $(".section1-cloud").hide()
        }else if(direction === "up"){
            $("body").removeClass("bg-gray")
            $(".section1-cloud").show()
        }
    }, {
        offset: '100%'
    });
} //animateSection1

const animateSection2 = function () {
    // 美學學美怎麼幫忙
    $(".howto-wrapper-start").waypoint(function(direction) {
        if(direction === "down"){
            $(".school").addClass("slideOut")
            $(".playground-combine").addClass("fadeIn");
            
             schoolHide();

            $(".howto-wrapper").addClass("scroll-animate-fast").css('opacity',1)
            $('.howto-wrapper .title, .howto-wrapper .content').addClass('in');
        }else if(direction === "up"){
            $(".howto-wrapper").removeClass("scroll-animate-fast").css('opacity',0)
            $(".part.sport, .part.classroom, .part.classroom-building, .part.classroom-building, .part.desk-and-playground .desk, .part.desk-and-playground .playground").removeAttr("style")
            
            $('.howto-wrapper .title, .howto-wrapper .content').removeClass('in')
        

            $('.school').removeClass('slideOut')
            $(".playground-combine").removeClass("fadeIn");
        }
    }, {
        offset: '0%'
    });

    // 美學學美怎麼幫忙離開
    $(".first-steps-wrapper").waypoint(function(direction) {  
        if(window.innerWidth >= 1200){
            $(window).on("scroll", function(){
                var scrolltop = $(window).scrollTop();
                var screenH = $(window).height();
                var percent = parseInt((scrolltop - $(".steps-wrapper").offset().top + screenH) / screenH * 100)

                if(percent >= 100){
                    percent = 100
                }

                if(percent <= 100){
                    if(percent <= 0){
                        percent = 0
                        $(".howto-wrapper .title").removeAttr("style")
                        $(".howto-wrapper .content").removeAttr("style")
                    }else{
                        $(".howto-wrapper .title").css("transform", "translateX(" + (percent * 0.5) + "vw)")
                        $(".howto-wrapper .content").css("transform", "translateX(" + (-1 * percent * 0.5) + "vw)")
                    }
                    
                    $('.howto-wrapper .playground.runway').css("transform", 'translateY(' + (-1 * percent * 0.8) + 'vh) translateZ(0)')
                    $('.howto-wrapper .playground.line').css("transform", 'translateY(' + (-1 * percent * 0.8) + 'vh) translateZ(0)')
                    $('.howto-wrapper .playground.player').css("transform", 'translateY(' + (-1* percent * 0.8) + 'vh) translateZ(0)')
                    $('.howto-wrapper .playground.door-left').css("transform", 'translateY(' + (-1 * percent * 0.8) + 'vh) translateZ(0)')
                    $('.howto-wrapper .playground.door-right').css("transform", 'translateY(' + (-1 * percent * 0.8) + 'vh) translateZ(0)')
                    $('.howto-wrapper .playground.green').css("transform",  'rotateX(45deg) translateY(' + (percent * 0.2) + 'vh) translateZ(0)')
                }
            })   
        }else{
            if(direction === "down"){
                $(".howto-wrapper .title").css("transform", "translateX(80vw)")
                $(".howto-wrapper .content").css("transform", "translateX(-80vw)")
                $('.howto-wrapper .playground.runway').css("transform", 'translateY(-80vh) translateZ(0)')
                $('.howto-wrapper .playground.line').css("transform", 'translateY(-80vh) translateZ(0)')
                $('.howto-wrapper .playground.player').css("transform", 'translateY(-80vh) translateZ(0)')
                $('.howto-wrapper .playground.door-left').css("transform", 'translateY(-80vh) translateZ(0)')
                $('.howto-wrapper .playground.door-right').css("transform", 'translateY(-80vh) translateZ(0)')
                $('.howto-wrapper .playground.green').css("transform", 'rotateX(45deg) translateY(20vh) translateZ(0)')
            }else if(direction === "up"){
                $(".howto-wrapper .title").css("transform", "translateX(0vw)")
                $(".howto-wrapper .content").css("transform", "translateX(0vw)")
                $('.howto-wrapper .playground.runway').css("transform", 'translateY(0vh) translateZ(0)')
                $('.howto-wrapper .playground.line').css("transform", 'translateY(0vh) translateZ(0)')
                $('.howto-wrapper .playground.player').css("transform", 'translateY(0vh) translateZ(0)')
                $('.howto-wrapper .playground.door-left').css("transform", 'translateY(0vh) translateZ(0)')
                $('.howto-wrapper .playground.door-right').css("transform", 'translateY(0vh) translateZ(0)')
                $('.howto-wrapper .playground.green').css("transform", 'rotateX(45deg) translateY(0vh) translateZ(0)')
            }
        } // end if
    }, {
        offset: '100%'
    });

    // step1 ~ 3
    $(".first-steps-wrapper").waypoint(function(direction) {
        if(direction === "down"){
            // console.log('step 1 down');
            $('.howto-wrapper').css("opacity",0)
            $(".step1, .step2, .step3").css("opacity",1)
            $(".step1, .step2, .step3").find('.step-image').addClass("in");
        }else if(direction === "up"){
            $('.howto-wrapper').css("opacity",1)
            $(".step1, .step2, .step3").css("opacity",0)
            $(".step1, .step2, .step3").find('.step-image').removeClass("in");
        }
        
    }, {
        offset: '0%'
    });

    // step4 ~ 6
    $(".second-steps-wrapper").waypoint(function(direction) {
        if(direction === "down"){
            // console.log('step 2 down');
            $(".step1, .step2, .step3").css("opacity",0)
            $(".step4, .step5, .step6").css("opacity",1)
            $(".step4, .step5, .step6").find('.step-image').addClass("in");
        }else if(direction === "up"){
            $(".step1, .step2, .step3").css("opacity",1)
            $(".step4, .step5, .step6").css("opacity",0)
            $(".step4, .step5, .step6").find('.step-image').removeClass("in");
            $(".final-container .content, .final-container .button").removeClass("in");
        }
        
    }, {
        offset: '0%'
    });

    //了解更多計畫內容
    $(".final-container").waypoint(function(direction) {
        if(direction === "down"){
            $(".final-container").css("opacity",1);
            $(".step4, .step5, .step6").css("opacity",0)
            $('body').removeClass('bg-gray').addClass('bg-pink')
            $('.section3-cloud').addClass("in");
            $(".final-container .content, .final-container .button").addClass("in");

        }else if(direction === "up"){
            $(".step4, .step5, .step6").css("opacity",1)
            $('body').removeClass('bg-pink').addClass('bg-gray')

            if($(window).width() > 1200){
                $(".final-container").css("opacity",0);
            }
            $('.section3-cloud').removeClass("in");
            // $(".final-container .content, .final-container .button").removeClass("in");
        }
    }, {
        offset: '0%'
    });

    $(".results-container").waypoint(function(direction) {
        if(direction === "down"){
            //我們的成果
            $(".final-container").addClass("out").css("opacity",0);
            $(".results-container").addClass("in");
            $('.section3-cloud').removeClass("in").addClass("out");
            
            $(".section4-cloud").addClass("in");
            $(".section4-cloud .cloud1").css({
                "transform": 'rotateX(46deg) translateY(-20%) translateZ(0)',
                "opacity": 0.8
            })

            $(".section4-cloud .cloud2").css({
                "transform": 'rotateX(46deg) translateY(-20%) translateZ(0)',
                "opacity": 0.8
            })

        }else if(direction === "up"){
            $(".final-container").removeClass("out").css("opacity",1);
            $(".results-container").removeClass("in");
            $('.section3-cloud').removeClass("out").addClass("in");

            $(".section4-cloud").removeClass("in");
            $(".section4-cloud .bg-image").removeAttr("style");
        }
    }, {
        offset: '0%'
    });

    let taiwanScroll = false
    $(".taiwan-map-container").waypoint(function(direction) {
        // 我們的成果離開
        $(window).on("scroll", function(){
            var scrolltop = $(window).scrollTop();
            var screenH = $(window).height();
            var percent = parseInt((scrolltop - $(".taiwan-map-container").offset().top + screenH) / screenH * 100)
            
            if(percent >= 100){
                percent = 100
            }

            if(percent <= 0){
                percent = 0
            }

            $(".results-container .title").css("transform",'translateX(' + (percent * 1.5) + 'vw)')
            $(".results-container .content").css("transform",'translateX('+ (-1 * percent * 1.5) + 'vw)')
        })   
    }, {
        offset: '100%'
    });

    $(".taiwan-map-container").waypoint(function(direction) {
        if(direction === "down"){
            setTimeout(function(){
                $(".section4-cloud .cloud1").css({
                    "transform": 'rotateX(46deg) translate(-100%, -20%) translateZ(0)',
                    "opacity": 0.8
                })

                $(".section4-cloud .cloud2").css({
                    "transform": 'rotateX(46deg) translate(100%, -20%) translateZ(0)',
                    "opacity": 0.8
                })
            },100) 

            $(".results-container").removeClass("in").addClass("out");

            //台灣地圖進入
            $(".taiwan-map-container").addClass("slideUp");

            //地點進入
            setTimeout(function(){
                //$(".section4-cloud").addClass("out")
                $(".taiwan-wrapper").addClass("levelup");
                $(".taiwan-map-container").addClass('actived')
            },400)
        }else if(direction === "up"){
            taiwanScroll = false
            $(".results-container").removeClass("out").addClass("in")
            //$(".section4-cloud").removeClass("out")
            $(".taiwan-wrapper").removeClass("levelup");
            $(".taiwan-map-container").removeClass("slideUp")
            $(".taiwan-map-container").removeClass('actived')
            $(".taiwan-map-container").removeAttr("style")

            $(".section4-cloud .cloud1").css({
                "transform": 'rotateX(46deg) translateY(-20%) translateZ(0)',
                "opacity": 0.8
            })

            $(".section4-cloud .cloud2").css({
                "transform": 'rotateX(46deg) translateY(-20%) translateZ(0)',
                "opacity": 0.8
            })
        }
        
    }, {
        offset: '50%'
    });

    $("#taiwanEnd").waypoint(function(direction) {
        if($(window).width() > 1200){
            taiwanScroll = true
        }
        // 地圖離開
        $(window).on("scroll", function(){
            if(taiwanScroll){
                const speed = 4;
                var scrolltop = $(window).scrollTop();
                var screenH = $(window).height();
                var percent = parseInt((scrolltop - $("#taiwanEnd").offset().top + screenH) / screenH * 100 - 100)

                if(percent >= 100){
                    percent = 100
                }

                if(percent <= 0){
                    percent = 0
                }

                $(".taiwan-map-container").css({
                    "transform": 'scale(' + (9 - (8 - percent)) + ')',
                    "opacity": (100 - (percent * speed)) / 100
                })
            }
        })   
    }, {
        offset: '0%'
    });
} //animateSection2

const animateSection3 = function () {
    let beforeAfter = false
    $(window).on("scroll", function(){
        var scrolltop = $(window).scrollTop();
        var screenH = $(window).height();

        // passing 過場
        var percent = parseInt((scrolltop - $("#taiwanEnd").offset().top + screenH) / screenH * 100 - 100)

        if(percent >= 100){
            percent = 100
        }

        if(percent <= 0){
            percent = 0
        }

        $(".passing").css("transform", 'translateY(' + (-1 * percent * 0.8) + '%) translateZ(0)')
    })

    //改造的類型有哪些？
    $(".section4").waypoint(function(direction) {
        // console.log("before & after in");
        if(direction === "down"){
            $(".before-after-container").addClass("slideUp");
            $("body").removeClass("bg-pink").addClass("bg-gray");
        }else if(direction === "up"){
            $(".before-after-container").removeClass("slideUp");
            $("body").removeClass("bg-gray").addClass("bg-pink");
        }
    }, {
        offset: '50%'
    });

    //改造的方法有哪些
    $("#afterEnd").find(".first").waypoint(function(direction) {
        if(direction === "up"){
            $(".before-after-container .cards").removeAttr("style")
        }
    }, {
        offset: '0%'
    });

    $("#afterEnd").find(".bf-each").each(function(index){
        // before
        var bfItem = $(this)

        bfItem.waypoint(function(direction) {
            var bfTarget = $(this.element).data("target");
            $(".logo, .social, .side-nav, .scroll-notice").removeClass("white");
            if(direction === "down"){
                $(".before-after-container .cards").css("transform","translateY(" + ((300 - (index * 200)) - 200) + "vh)")
                $("#image-card-wrapper" + bfTarget).addClass("actived");
                $("#text-wrapper" + bfTarget).addClass("actived");
            }else if(direction === "up"){
                $(".before-after-container .cards").css("transform","translateY(" + ((300 - ((index - 1) * 200)) - 255) + "vh)")
                $("#image-card-wrapper" + bfTarget).removeClass("actived");
                $("#text-wrapper" + bfTarget).removeClass("actived");
            }
        }, {
            offset: '100%'
        });

        bfItem.waypoint(function(direction) {
            var bfTarget = $(this.element).data("target");
            $(".logo, .social, .side-nav, .scroll-notice").addClass("white");

            if(direction === "down"){
                $(".before-after-container .cards").css("transform","translateY(" + ((300 - (index * 200)) - 255) + "vh)")
                $("#image-card-wrapper" + bfTarget).find(".after-card").css("transform", "translateY(-100%)");
                $("#text-wrapper" + bfTarget).find(".text:eq(1)").addClass("actived");
            }else if(direction === "up"){
                $(".before-after-container .cards").css("transform","translateY(" + ((300 - (index * 200)) - 200) + "vh)")
                $("#image-card-wrapper" + bfTarget).find(".after-card").css("transform", "translateY(0%)");
                $("#text-wrapper" + bfTarget).find(".text:eq(1)").removeClass("actived");
            }
        }, {
            offset: '0%'
        });

        bfItem.waypoint(function(direction) {
            var bfTarget = $(this.element).data("target");
            $("#image-card-wrapper" + bfTarget).find(".after-card").css("transform", "translateY(-200%)");
        }, {
            offset: '-100%'
        });
    }) // end afterEnd

    //一起來看看更多改造案例過場
    let scrollL = 0;
    let refactorySCroll;
    $(".refactory-container").waypoint(function(direction) {
        // console.log('refactory-container in');
        
        if(direction === "down"){
            $(".refactory-container").addClass("in");
            $(".text-card, .image-card-wrapper, .text-wrapper").addClass("out")
            $(".before-after-container .cards").css("transform","translateY(-900vh)")
        }else if(direction === "up"){
            // $(".refactory-container").removeClass("in");
            $(".text-card, .image-card-wrapper, .text-wrapper").removeClass("out")
        }

        $(window).on("scroll", function(){
            if($(".refactory-container").hasClass("in")){
                var scrolltop = $(window).scrollTop();
                var screenH = $(window).height();
                var percent = parseInt((scrolltop - $("#refactoryEnd").offset().top + screenH) / screenH * 100)

                // if(percent >= 100){
                //     percent = 100
                // }

                if(percent <= 0){
                    percent = 0
                }
                if(percent >= 100){
                    $(".first-page .scale-text-wrapper").css({
                        "width": (100 - percent) + "%",
                        "height": (100 - percent) + "%"
                    })
                }else{
                    $(".first-page .scale-text-wrapper").css({
                        "width": "100%",
                        "height": "100%"
                    })
                    
                    $(".first-page .scale-text-wrapper .block").css("opacity", 1)
                }

                if(percent >= 150){
                    $(".first-page .scale-text-wrapper .block").css("opacity", (200 - percent) / 100)
                }

                // 符號過場
                if(percent >= 100){
                    setTimeout(function(){
                        $(".logo, .social, .side-nav, .scroll-notice").removeClass("white");
                        $(".refactory-container .first-page .icon").addClass('grown')
                    }, 200)
                }else{
                    $(".logo, .social, .side-nav, .scroll-notice").addClass("white");
                    $(".refactory-container .first-page .icon").removeClass('grown')
                }

            } // end if
        }) // end scroll
    }, {
        offset: '0%'
    });

    $(".refactory-each").each(function(index){
        var refactoryItem = $(this)

        refactoryItem.waypoint(function(direction) {
            let moveLeft = -90 * index

            if(index == 5){
                moveLeft = -500
            }else if(index > 0){
                moveLeft = moveLeft - (10 * (index - 2))
            }else{
                moveLeft = 0
            }

            // 符號旋轉
            if(index == 1){
                $(".first-page").find(".icon").css("transform","scale(1) rotate(-270deg)");
            }

            if(index == 0){
                $(".first-page").find(".icon").css("transform","scale(1) rotate(0deg)");
            }

            $(".horizontal-wrapper").css("transform","translateX(" + moveLeft + "vw)");
            $(".refactory-container .page:eq(" + index + ")").addClass('active')
            $(".refactory-container .page:eq(" + index + ") .content").addClass('slideLeft')
        }, {
            offset: '0%'
        });
    });

    // 教育現場怎麼說？
    $(".scale-text-each").waypoint(function(direction) {
        // console.log("教育現場怎麼說？");
        $(".refactory-container").addClass("in");
        $(".horizontal-wrapper").css("transform","translateX(-500vw)");

        if(direction === "down"){
            if($(window).scrollTop() > 100){
                $(".logo, .social, .side-nav, .scroll-notice").addClass("white");
            }
            $(".last-page .scale-text-wrapper").css({
                "width": "64rem",
                "height": "36rem",
                "border-radius": "18rem"
            })

            $(".last-page .scale-text-wrapper .block").css("opacity",1)
            $(".last-page .bg-mask").css("opacity",1)
            $(".last-page .scale-text-wrapper .triangle").addClass("slideUp")
        }else if(direction === "up"){
            $(".logo, .social, .side-nav, .scroll-notice").removeClass("white");
            $(".last-page .scale-text-wrapper, .last-page .scale-text-wrapper .block, .last-page .bg-mask, .last-page .scale-text-wrapper .animate-circle").removeAttr("style")
            $(".last-page .scale-text-wrapper .triangle").removeClass("slideUp")
        }
    }, {
        offset: '0%'
    });

    $(".refactoryFooter").find(".card").each(function(index){
        var cardItem = $(this)

        cardItem.waypoint(function(direction) {
            if(direction === "down"){
                cardItem.addClass("slideUp")

                if(index == 0){
                    $(".last-page .scale-text-wrapper .block").css("opacity",0)
                    $(".last-page .scale-text-wrapper .animate-circle").css("opacity",0.5)
                    $(".last-page .scale-text-wrapper .animate-circle").find('.circle').addClass('grown')

                    $(".animate-circle .circle:eq(2)").addClass('small')
                }

                if(index == 1){
                    $(".animate-circle .circle:eq(1)").addClass('small')
                    $(".animate-circle .circle:eq(2)").removeClass('small')
                }
                
                if(index == 2){
                    $(".animate-circle .circle:eq(0)").addClass('small')
                    $(".animate-circle .circle:eq(1)").removeClass('small')
                }
            }else if(direction === "up"){
                cardItem.removeClass("slideUp")

                if(index == 0){
                    $(".last-page .scale-text-wrapper .block").css("opacity",1)
                    $(".last-page .scale-text-wrapper .animate-circle").css("opacity",0)
                    $(".last-page .scale-text-wrapper .animate-circle").find('.circle').removeClass('grown')

                    $(".animate-circle .circle:eq(2)").removeClass('small')
                }

                if(index == 1){
                    $(".animate-circle .circle:eq(1)").removeClass('small')
                    $(".animate-circle .circle:eq(2)").addClass('small')
                }
                
                if(index == 2){
                    $(".animate-circle .circle:eq(0)").removeClass('small')
                    $(".animate-circle .circle:eq(1)").addClass('small')
                }
            }
        }, {
            offset: '60%'
        });
    })
    
    $(".video-wrapper").waypoint(function(direction) {
        if(direction === "down"){
            $(".animate-circle .circle:eq(0)").removeClass('small')
        }else if(direction === "up"){
            $(".animate-circle .circle:eq(0)").addClass('small')
        }
    }, {
        offset: '100%'
    });

    $(".video-wrapper").waypoint(function(direction) {
        if(direction === "down"){
            $(".logo, .social, .side-nav, .scroll-notice").removeClass("white");
            $(".page.last-page .bg-mask").addClass("bg-pink");
        }else if(direction === "up"){
            $(".logo, .social, .side-nav, .scroll-notice").addClass("white");
            $(".page.last-page .bg-mask").removeClass("bg-pink");
        }
    }, {
        offset: '0%'
    });
} //animateSection3

const animateSection4 = function () {
    $(".last-section").waypoint(function(direction) {
        if(direction === "down"){
            $(".last-section .bubble").addClass("slideUp");
        }else if(direction === "up"){
            $(".last-section .bubble").removeClass("slideUp");
        }
    }, {
        offset: '60%'
    });

    $(".last-section .content-wrapper").each(function(index){
        var contentItem = $(this)

        contentItem.waypoint(function(direction) {
            if(direction === "down"){
                contentItem.addClass('show')
            }else if(direction === "up"){
                contentItem.removeClass('show')
            }
        }, {
            offset: '70%'
        });
    })
} //animateSection4

const sidebarCurrent = function (cur,percent) {
    if(percent >= 100){
        percent = 100
    }
    document.querySelector('.side-nav').querySelectorAll(".side-bar")[cur].querySelector('.bar').style.height = percent + "%"
}

const GetPercent = function (p){
    let percent = p

    if(percent <= 0){
        percent = 0
    }

    if(percent >= 100){
        percent = 100;
    }

    return percent
}

sidebarCurrent(0,100)
$(window).on("scroll", function(){
    var scrolltop = $(window).scrollTop();
    var screenH = $(window).height();

    var percent0 = parseInt((1 - ($(".howto-wrapper-start").offset().top - scrolltop) / ($(".howto-wrapper-start").offset().top - screenH)) * 100)
    var percent1 = parseInt((1 - (menuPos["#section3"] - scrolltop) / menuPos["#section3"]) * 100)
    var percent2 = parseInt((1 - (menuPos["#results-container"] - scrolltop) / (menuPos["#results-container"] - menuPos["#section3"])) * 100)
    var percent3 = parseInt((1 - (menuPos["#retrofit-category"] - scrolltop) / (menuPos["#retrofit-category"] - menuPos["#results-container"])) * 100)
    var percent4 = parseInt((1 - (menuPos["#refactory"] - scrolltop) / (menuPos["#refactory"] - menuPos["#retrofit-category"])) * 100)
    var percent5 = parseInt((1 - (menuPos["#refactoryFooter"] - scrolltop) / (menuPos["#refactoryFooter"] - menuPos["#refactory"])) * 100)
    var percent6 = parseInt((1 - (menuPos["#last-section-bubble"] - scrolltop) / (menuPos["#last-section-bubble"] - menuPos["#refactoryFooter"])) * 100)
    var percent7 = parseInt((1 - ($(document).innerHeight() - scrolltop - screenH) / ($(document).height() - menuPos["#last-section-bubble"] - screenH)) * 100)

    // sidebarCurrent(0,GetPercent(percent0))
    sidebarCurrent(1,GetPercent(percent1))
    sidebarCurrent(2,GetPercent(percent2))
    sidebarCurrent(3,GetPercent(percent3))
    sidebarCurrent(4,GetPercent(percent4))
    sidebarCurrent(5,GetPercent(percent5))
    sidebarCurrent(6,GetPercent(percent6))
    sidebarCurrent(7,GetPercent(percent7))

    //
    if(scrolltop >= screenH){
        $(".scroll-notice").addClass("fadeOut");
    }else{
        $(".scroll-notice").removeClass("fadeOut");
    }

})

$(".side-nav a, .slideTo").on("click", function(e){
    let section_target = $(this).data("target");
    if($(section_target).length > 0){
        window.scrollTo(0, menuPos[section_target]);

        e.preventDefault(true)
    }
})